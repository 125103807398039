import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import Header from '../../components/header'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageFull } from '../../components/imageelements'
import { Helmet } from "react-helmet"
import { AdContainer } from '../../components/adstyling'
import { MainBodySection } from '../../components/generalstyling'
import { QandA } from '../../components/qanda'
import { Link } from 'gatsby'


// markup
const GlyndebournePage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Glyndebourne Festival Opera | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operahouses/glyndebourne" />
          <meta name="description" content="Glyndebourne Festival Opera near Lewes, Sussex. The UK's most important (and eccentric) summer opera festival. How to get tickets, how to get there and what to wear!" />
        </Helmet>
        <Hero title="Glyndebourne"/>
        <MainBodySection>
                    
          <Header title="Glyndebourne Festival Opera"/>

          <p>The Glyndebourne Festival, running each Summer from May to August, is one of those institutions whose existence says much about the glorious eccentricity of the British. A country mansion with a world class opera house tacked on the side.</p>
          
          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <ImageFull>
            <StaticImage src="../../images/operahouses/glyndebourne/glyndebourne_hall.jpg" alt="Glyndebourne opera house viewed from the outside"/>
            <figcaption>The Glyndebourne opera house</figcaption>
          </ImageFull>

          <p>The smallish auditorium, seating 1200, gives a slightly more intimate feel than the majority of top class opera houses. The opera standards are amazingly high considering the house receives no government subsidy and the prices are kept within the standard operatic range (so not cheap by any stretch of the imagination). The singers aren't always drawn from the biggest names but the consistency of casting is impressive. Two fantastic orchestras are used, either The Orchestra of the Age of the Enlightenment or the London Philharmonic Orchestra depending on the period of the opera. The current music director is Robin Ticciati.</p>

          <p>Traditionally Mozart has received a very strong showing at Glyndebourne though in recent years the range of rep has known no limits. Starting with <i>Tristan und Isolde</i> and more recently <i>Die Meistersinger</i>, Wagner has finally got a showing, the orchestra pit seemingly swelling to fit the scale of the pieces.</p>

          <ImageFull>
            <StaticImage src="../../images/operahouses/glyndebourne/glyndebourne_tristan.jpg" alt="Glyndebourne's Tristan und Isolde"/>
            <figcaption>Glyndebourne's magnificent <i>Tristan und Isolde</i> &copy; Glyndebourne</figcaption>
          </ImageFull>

          <p>Attending Glyndebourne is a full day out -- and one of the best days out we know! The rural location means many have to travel a considerable distance to get to Glyndebourne and the performances start early, 5pm-ish at latest. The performance is punctuated by a very long interval in which elaborate picnics are eaten on the lawn. You can bring your own, buy a hamper or eat in one of the restaurants (much less fun…).</p>

          <ImageFull>
            <StaticImage src="../../images/operahouses/glyndebourne/glyndebourne_picnic.jpg" alt="A Glyndebourne picnic"/>
            <figcaption>Picnicking on the lawns</figcaption>
          </ImageFull>

        </MainBodySection>


        <div id="ezoic-pub-ad-placeholder-103"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection> 

          <Header title="The Festival Society"/>

          <p>Glyndebourne is still predominantly an opera house driven by members. Tickets are first sold to members of the “Festival Society”, then “Associate Members” and finally to the general public. For many shows almost all the tickets sell before the general public gets access (and what remains tends to be at the highest end of the price range). The exception to this are some of the restricted view seats and standing places which are offered first to the general public.</p>

          <p>There is a considerable waiting list to become a member of the Festival Society (there are around 9,000 members) and each member pays annual dues of £155. Even for these members there is a ballot for tickets (and no guarantee you’ll get your first choice by any means). Associate members pay £80 a year and anyone can sign up for this (though the number of new members is capped each year). Associate membership also acts as a waiting list for those who want to become full members.</p>

          <p>If you’re not a member there are ways to get tickets, see the FAQs, but it isn’t always easy. Glyndebourne is a private, unsubsidised venture (unlike the <Link to="/operahouses/royalopera">Royal Opera</Link> and ENO), so they do not hold back tickets to ensure fair access to the general public.</p>

          <Header title="Glyndebourne on Tour"/>

          <p>Glyndebourne on Tour pretty much does what it says on the tin. Each year several of the mainstage productions travel around the country to reach a wider audience. Ticket prices are much lower and despite less starry casting musical standards are generally extremely high. If you’re really keen to visit Glyndebourne itself, the tour actually opens with a run of performances at Glyndebourne giving you much of the fun but for much less money (and there’s no dress code either).</p>

          <Header title="FAQ: Attending Glyndebourne"/>

          <QandA>
            <li>
              <h4>Getting there</h4>

              <p>Glyndebourne's own <a href="http://www.glyndebourne.com/your-visit/how-to-get-here/">website has better directions</a> than we could ever write.
                  Driving has obvious perks but if you're coming from London the train route is surprisingly easy with Glyndebourne laying on a terrific free
                  coach service between the station and the opera house. What's more if the show runs over -- they hold the train!</p>
            </li>

            <li>
              <h4>Is there a Dress code?</h4>

              <p>Glyndebourne is a rare opera house where there is still a black tie dress code at every performance. Going to Glyndebourne is a total experience and part of that is dressing up. You’ll see a range of clothing ranging from the extremely flamboyant to more conventional dinner jackets. If you can’t get your hands on a tuxedo, regular suits will do just fine for the men, cocktail dresses for the women. It can get chilly though so prepare for all weathers.</p>
            </li>

            <li>
              <h4>Programmes and Information?</h4>

              <p>There are no individual programs for each opera but one large season book that covers all six. This sells for £20, fairly steep though very well put together with plenty of content and limited advertising. Cast sheets are available for free.</p>
            </li>

            <li>
              <h4>Cloakroom?</h4>

              <p>There’s a free cloakroom on the stalls level, one flight of stairs down from the main level. However, as you’ll be dumping all your picnic stuff outside you probably won't need it. We’ve never used it personally.</p>
            </li>

            <li>
              <h4>Can I get in late?</h4>

              <p>Definitely not though as you’ll probably be arriving significantly before the performance starts, wandering the beautiful gardens, pre-performance fizz and snacks etc. this really shouldn’t be an issue.</p>
            </li>

          </QandA>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-104"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection> 

          <Header title="FAQ: Cheap Tickets!"/>

          <QandA>

            <li>
              <h4>Sold Out Shows?</h4>

              <p>Nearly every performance sells out. However, as a result of most of the tickets being sold far in advance of performances, there are returns available for most shows. Glyndebourne sends out a regular email, <a href="https://www.glyndebourne.com/your-visit/returns-club/">the returns club</a> detailing returns availability for upcoming performances. We’ve also heard of people having luck just calling up and asking. For the very brave showing up on the day is said to be a pretty effective method though if you’re coming from London it’s a long way to go to be disappointed.</p>
            </li>

            <li>
              <h4>Are all the seats expensive?</h4>

              <p>Glyndebourne is a small theatre which is fabulous is some regards but means there are few bad seats. This also means that bottom price, full view seats start at about £60 (and significantly more for some operas, particularly when they do Wagner). There are some restricted view seats and standing places but overall Glyndebourne is not a cheap outing. Under 30s have it very good indeed however (see the under 30s Question below)</p>
            </li>

            <li>
              <h4>Discounts?</h4>

              <p>Discounts are basically unheard of. They’ve turned up a couple of times in the last few years but very rarely indeed.</p>
            </li>

            <li>
              <h4>Under 30s?</h4>

              <p>Glyndebourne to their eternal credit have <a href="http://glyndebourne.com/under30">one of the best young persons programs</a> we’ve come across anywhere. At a few performances throughout the season (once each for 2-3 different operas) they sell most of the seats in the opera house to anyone under 30 for only £30 a ticket. They also reserve a selection of standing places for under 30s at every performance. We’ve had some ludicrously wonderful seats through this scheme!</p>
            </li>

          </QandA>

        </MainBodySection>
    </Layout>
  )
}

export default GlyndebournePage
